<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1><small>Module</small> {{ name || "inconnu" }}</h1>
            <b-overlay
                class="mr-3"
                :show="isWaiting || isLoading"
                spinner-variant="kalkin-2"
                opacity="1"
                variant="white"
            >
                <b-icon-exclamation-triangle-fill
                    v-show="!isValid"
                    variant="danger"
                    v-b-tooltip.leftbottom.hover.v-danger="
                        'Echec de la sauvegarde. Les données contiennent des erreurs'
                    "
                />
                <b-icon-check-circle-fill
                    v-show="isValid && hasChanged"
                    variant="kalkin-2"
                    v-b-tooltip.leftbottom.hover.v-success="'Sauvegardé!'"
                />
            </b-overlay>
        </div>

        <div v-if="name && config && data">
            <b-tabs
                v-if="targets.length > 1"
                class="border rounded bg-light"
                nav-class="pt-1"
                content-class="p-2 bg-white"
                :vertical="useVerticalMode"
                :pills="useVerticalMode"
            >
                <b-tab v-for="t in targets" :key="t.id">
                    <template #title>
                        <small>{{ targetInfos.name }}</small>
                        {{ t.name }}
                        <b-icon-check2-circle
                            v-if="isTargetValid[t.id]"
                            scale="0.8"
                            variant="kalkin-2"
                        />
                        <b-icon-x v-else variant="danger" />
                    </template>
                    <!-- FormProjectModule 1/2 -->
                    <FormProjectModule
                        :project-id="projectId"
                        :config="config"
                        v-model="data[t.id]"
                        @input="hasChanged = true"
                        @validate="isTargetValid[t.id] = $event"
                        @save="onSave"
                    />
                </b-tab>
                <template #tabs-start>
                    <b-nav-item disabled>
                        <b class="text-kalkin-1">Cible du module </b>
                    </b-nav-item>
                </template>
            </b-tabs>
            <div v-else-if="targets.length == 1">
                <!-- FormProjectModule 2/2 -->
                <FormProjectModule
                    :project-id="projectId"
                    :config="config"
                    v-model="data[targets[0].id]"
                    @input="hasChanged = true"
                    @validate="isTargetValid[targets[0].id] = $event"
                    @save="onSave"
                />
            </div>
            <div
                v-else
                v-show="!isLoadingTargets"
                class="text-center text-danger my-5"
            >
                <i>Aucune cible définie ou existante pour le module</i>
            </div>
        </div>
        <div v-else v-show="!isLoading" class="text-center text-danger my-5">
            Module non trouvé
        </div>
    </div>
</template>

<script>
import { debounce as _debounce } from "lodash";
import Module from "@/model/modules";
import FormProjectModule from "@/components/model/projectmodule/FormProjectModule";

export default {
    components: {
        FormProjectModule,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        configId: { type: [String, Number], required: false },
        name: { type: String },
        config: { type: Object },
        data: { type: Object },
        targetType: { type: String },
        isLoading: { type: Boolean, default: false },
    },

    data: function() {
        return {
            level: this.configId ? "config" : "project",

            targetInfos: {},
            targets: [],

            isTargetValid: {},
            isWaiting: false,
            isLoadingTargets: false,
            hasChanged: false,
        };
    },

    computed: {
        useVerticalMode() {
            return this.targets.length > 5;
        },

        isValid() {
            return Object.values(this.isTargetValid).every(t => t);
        },
    },

    watch: {
        async targetType() {
            await this.fetchTargets();
        },
    },

    methods: {
        async fetchTargets() {
            this.isLoadingTargets = true;
            const targetInfos = Module.getModuleTarget(this.targetType);
            if (targetInfos) {
                let response = await this.$store.dispatch(
                    targetInfos.action[this.level],
                    {
                        projectId: this.projectId,
                        configId: this.configId,
                    },
                );
                if (response) {
                    if (Array.isArray(response)) {
                        this.targets = response;
                    } else {
                        this.targets = [response];
                    }
                    this.isTargetValid = this.targets.reduce(
                        (a, c) => ((a[c.id] = true), a),
                        {},
                    );
                    this.targetInfos = targetInfos;
                }
            }
            this.isLoadingTargets = false;
        },

        async onSave() {
            this.isWaiting = true;
            this.save();
        },

        save: _debounce(async function() {
            if (this.isValid && this.hasChanged) {
                this.$emit("save", this.data);
            }
            this.isWaiting = false;
        }, 1000),
    },

    async beforeMount() {
        await this.fetchTargets();
    },
};
</script>
